<template>
  <Member
    title="Sig. Giuseppe Serra"
    description="Referente programmazione percorso clinico dei pazienti"
    text="Figura organizzativa per gestione percorso paziente, pianificazione esami e visite ambulatoriali per pazienti affetti da patologica oncologica e per pazienti con patologia benigna funzionale. Gestione e pianificazione percorso chirurgico (Prericovero e Ricovero) e Sedute Operatorie. Controllo e organizzazione attività ambulatoriale della UOC.

Esperienza Lavorativa precedente presso l’Istituto Clinico Humanitas dal 1996 al 2014 con mansioni di impiegato amministrativo presso Area Ricoveri SSN, Prericoveri SSN, Pronto Soccorso e Segreterie di Reparto per pianificazione ricoveri ed interventi presso le chirurgie generali"
    :formation="['', '', '', '']"
    :pub="false"
    publication=""
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Serra",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
